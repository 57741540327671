import { Injectable, inject } from '@angular/core';
import { ApiService } from '@features/api';
import { Observable } from 'rxjs';
import { FileHistory } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  protected api = inject(ApiService);

  list(config?: { limit?: number; page?: number }): Observable<FileHistory> {
    return this.api.get('/user/contacts/call/history/files', config);
  }
}
